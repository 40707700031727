import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { gql } from "@apollo/client";
import { useQueryParam, ArrayParam } from "use-query-params";

import { unwrap } from "../components/WagtailWrapper";
import {
  Section,
  TightContent,
  GrayInset,
  Grid,
  WideContent,
  BannerFrame,
  Framed,
  Column,
} from "../components/Layouts";
import { Filters } from "../components/Facets";
import {
  ResearchPartnershipCard,
  ResearchPartnershipCardFragment,
} from "../components/Card";
import { SectionFragment } from "../components/Wagtail";
import { Smallcaps } from "../components/Prose";

const getFacets = (items) =>
  [
    {
      label: "Name",
      options: Object.values(
        items?.reduce((acc, { title }) => {
          const text = title;
          if (text && !acc[text]) {
            acc[text] = { label: text, value: text };
          }
          return acc;
        }, {})
      ).sort((a, b) => (a.label > b.label ? 1 : -1)),
    },
  ].sort((a, b) => a.options.length - b.options.length);

const normalize = (items) => {
  return items
    ?.filter((p) => p?.lastPublishedAt)
    ?.map((item) => {
      item.tags = [item?.title].filter(Boolean);

      return item;
    })
    .sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.title) - new Date(a.title);
    });
};

export const Component = ({ page, snippets, images }) => {
  const [data, setData] = useState(page?.children);
  const [facets, setFacets] = useState({});
  const [items, setItems] = useState(data);
  const [filters, setFilters] = useQueryParam("filters", ArrayParam);

  useEffect(() => {
    setData(normalize(data));
  }, [page]);

  useEffect(() => {
    setItems(
      data?.filter((e) =>
        filters ? filters.every((f) => e.tags?.includes(f)) : true
      )
    );
  }, [filters, data]);

  useEffect(() => {
    setFacets(getFacets(items));
  }, [items]);

  return (
    <BannerFrame
      images={images}
      page={page}
      snippets={snippets}
      url={`research-partnerships`}
      title={page?.title}
    >
      <Framed>
        <Column>
          <Section>
            <TightContent>
              <Filters facets={facets} filters={filters} setter={setFilters} />
              <div className="mt-12 flex-wrap flex center justify-around">
                <div>
                  <span className="text-2xl leading-8 font-extrabold sm:text-3xl sm:leading-9">
                    {items?.length}
                  </span>
                  <Smallcaps>Research Partnerships</Smallcaps>
                </div>
              </div>
            </TightContent>
          </Section>
          {!!(items?.length > 0) && (
            <GrayInset>
              <WideContent>
                <Grid>
                  {items?.map((m) => (
                    <ResearchPartnershipCard key={m.slug} item={m} />
                  ))}
                </Grid>
              </WideContent>
            </GrayInset>
          )}
        </Column>
      </Framed>
    </BannerFrame>
  );
};

export const previewQuery = gql`
  query previewResearchPartnershipListingPage(
    $contentType: String!
    $token: String!
  ) {
    images {
      id
      title
      src
    }
    snippets {
      __typename
      ... on CallToAction {
        name
        url
        document {
          id
          file
        }
        id
      }
    }
    page(contentType: $contentType, token: $token) {
      id
      slug
      title
      seoTitle
      seoDescription
      ... on ResearchPartnershipListingPage {
        header
        subheader
        headerText {
          ...SectionFragment
        }
        headerImage {
          title
          src
        }
        children {
          ...ResearchPartnershipCardFragment
        }
      }
    }
  }
  ${SectionFragment}
  ${ResearchPartnershipCardFragment}
`;

export const query = graphql`
  query ResearchPartnershipListingPage {
    wagtail {
      images {
        id
        title
        src
      }
      snippets {
        __typename
        ... on Wagtail_CallToAction {
          name
          url
          document {
            id
            file
          }
          id
        }
      }
      page(slug: "research-partnership-listing") {
        id
        slug
        title
        seoTitle
        seoDescription
        ... on Wagtail_ResearchPartnershipListingPage {
          header
          subheader
          headerText {
            ...Wagtail_SectionFragment
          }
          headerImage {
            title
            src
          }
          children {
            ...Wagtail_ResearchPartnershipCardFragment
          }
        }
      }
    }
  }
`;

export default unwrap(Component);
